import type { NextPage } from "next";
import Head from "next/head";
import Link from "next/link";
import Header from "../components/commonComponents/Header/Header";
import DummyForm from "../components/DummyForm";
import { Router, useRouter } from "next/router";
import { useEffect } from "react";


const Home: NextPage = () => {
  const Router = useRouter();
  useEffect(()=>{
    Router.replace('/login');
  },[])
  return (
    <>
      {/* <Header /> */}
      <div className="hi">{/* <DummyForm /> */}</div>
      {/* {Router.replace('/login')} */}
    </>
  );
};

export default Home;
